import React from 'react';
import WOW from 'wowjs';
import 'animate.css/animate.min.css';
import { useEffect } from 'react';


const CallToAction = () => {
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
      }, []);
  return (
   
      <div className=" animate__animated animate__fadeInUp" style={{ backgroundColor:'#2d2b46'}}>
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row items-center p-8">
            <div className="lg:w-9/12 text-center lg:text-left mb-4 lg:mb-0 pl-4 lg:pl-20">
              <h3 className="cta-title text-3xl md:text-4xl lg:text-5xl font-bold pb-6 text-white">Call To Action</h3>
              <p className="cta-text text-sm md:text-base text-white">
                Ready to Transform Your Digital Vision? Contact Us Today.
              </p>
            </div>
            <div className="lg:w-3/12 cta-btn-container text-center mt-4 lg:mt-0 ">
              <a
                href="mailto:bit.logics.sol@gmail.com"
                className="cta-btn align-middle bg-[#2d2b46]  text-white py-2 px-4 rounded inline-block border-4 border-white hover:bg-cyan-300 duration-300   hover:border-cyan-300 duration-300"
                          >
                Call To Action
              </a>
            </div>
          </div>
        </div>
      </div>

  );
};

export default CallToAction;
