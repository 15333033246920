import React from 'react';
import Header from '../Components/Header';
import Detailhero from '../Components/Detailcomponent/Detailhero';
import Prodetail from '../Components/Detailcomponent/Prodetail';
import Problems from '../Components/Detailcomponent/Problems';
import Range from '../Components/Detailcomponent/Range';
import Feathurs from '../Components/Detailcomponent/Feathurs';
import Clientsection from '../Components/Clientsection';
import { useLocation } from 'react-router-dom';

const Details = () => {
  const location = useLocation();
  const selectedItem = location?.state?.selectedItem || {};
  console.log(selectedItem)
  
  return (
    <>
    <div className="animated-slide-in-from-top">
      {/* {/* <Header /> */}
      <Detailhero selectedItem={selectedItem} />
       <Prodetail selectedItem={selectedItem} />
      <Problems selectedItem={selectedItem} />
      {/* <Range selectedItem={selectedItem} /> */}
      <Feathurs selectedItem={selectedItem} /> 
      <Clientsection /> 
      </div>
    </>
  );
};

export default Details;
