import React, { useEffect } from 'react';
import WOW from 'wowjs';
import 'animate.css/animate.min.css';

const ServiceBox = ({ data }) => {
  const { bgColor, iconColor, title, description, Icon } = data;

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <div
      className='flex flex-col items-center justify-center bg-white h-64 p-2 rounded-lg shadow-md wow animate__animated animate__bounceInUp hover:scale-110 duration-300'
      style={{ width: '350px' }}
      data-wow-duration="2.5s"
    >
      <div className='flex justify-center items-center rounded-full w-14 h-14' style={{ background: bgColor, color: iconColor }}>
        {Icon && <Icon />}
      </div>
      <h4 className='text-2xl p-3 font-bold hover:text-cyan-300  transition duration-300 ease-in-out'>
        <a href="">{title}</a>
      </h4>
      <p className='px-6' style={{ color: '#535074' }}>{description}</p>
    </div>
  );
};

export default ServiceBox;
