import React from 'react';

const Detailhero = ({ selectedItem }) => {
 
  const items = [selectedItem];

  return (
    <div>
      {items.map((item, index) => (
        <div key={index} className='grid grid-cols-1 sm:grid-cols-2 h-full w-full '>
          <div className='flex flex-col justify-center items-center p-8 sm:p-12 '>
            <div className='w-full '>
              <h1 className='text-xl sm:text-5xl font-semibold pb-3 uppercase text-gray-700'>{item.main.title}</h1>
              <p className='text-gray-700 text-lg sm:text-xl '>{item.main.longDescription}</p>
            </div>
          </div>

          <div className='flex justify-center items-center p-8 md:p-8  '>
            <div className='w-full '>
              <img src={item.main.coverImage} className='h-full w-full object-cover rounded-lg' alt={item.fullname} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Detailhero;
