import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const EmailForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

   
    const serviceId = 'service_kng95e9';
    const templateId = 'template_ddd4sqn';
    const userId = 'PWWcS1jzatwG5rzyx';

    emailjs
      .send(serviceId, templateId, { from_name: name, from_email: email, message }, userId)
      .then((response) => {
        alert("Message send successfully")
        setName("")
        setEmail("")
        setMessage("")
        console.log('Email sent successfully:', response);
  
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        
      });
  };

  return (
    <div className=''>
    <h1 className=" font-semibold pb-3 uppercase pt-2 " style={{ color: "#413e66"  }}>
        Send us a Message
      </h1>
   
      
      <form onSubmit={sendEmail} className="space-y-4 pt-2">
        <div>
          <label htmlFor="name" className="block text-gray-600">
           
          </label>
          <input
            type="text"
            id="name"
            placeholder='Your Name'
            className="w-full p-2 border rounded-md"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-gray-600">
           
          </label>
          <input
            type="email"
            id="email"
            className="w-full p-2 border rounded-md"
            placeholder='Your Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="message" className="block text-gray-600">
           
          </label>
          <textarea
            id="message"
            className="w-full p-2 border rounded-md"
            value={message}
            placeholder='Type here...'
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className='flex flex-row justify-center '>
        <button
          type="submit"
          className="bg-cyan-400 text-white py-2 px-8  rounded-md hover:bg-cyan-600 duration-300 ease-in-out  items-center"
        >
          Send Email
        </button></div>
      </form>
    
    </div>
  );
};

export default EmailForm;
