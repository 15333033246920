import React from 'react'
import Header from '../Components/Header'
import Hero from '../Components/Hero'
import Aboutus from '../Components/Aboutus'
import Service from '../Components/Service'
import Chooseus from '../Components/Chooseus'
import Counters from '../Components/CounterItem'
import CallToAction from '../Components/CallToAction'
import Portfolio from '../Components/Portfolio'
import Testimonials from '../Components/Testimonial'
// import Team from '../Components/TeamMember'
import raheelImage from '../assets/raheel.jpeg';
import umerImage from '../assets/umar.jpeg';
import TeamMember from '../Components/TeamMember'
import Clientsection from '../Components/Clientsection'
import Fotter from '../Components/Fotter'



const Home = () => {
  
  return (
    <div className='w-full overflow-hidden'>
      {/* <Header/> */}
      <Hero/>
       <Aboutus/> 
       <Service/> 
      <Chooseus/> 
      <Counters/>
      <CallToAction/>
      <Portfolio/>
      <Testimonials/>
      <TeamMember/>
      <Clientsection/>
      <Fotter/>  
     

    </div>
  )
}

export default Home
