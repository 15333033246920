import React from 'react';
import intro from '../assets/intro-bg.jpg';
import Header from './Header';
import svg from '../assets/intro-img.svg';

const Hero = () => {
  return (
    <div className="bg-cover h-screen w-screen  overflow-hidden   " id='intro' style={{ backgroundImage: `url(${intro})` }}>
      <Header />
      <div className='flex flex-col sm:flex-row pt-2  items-center  '>
        <div className="flex-1 flex flex-col items-start justify-start h-full pt-40 pl-4 sm:pl-16  ">
          <h1 className='text-2xl sm:text-6xl text-gray-700 font-bold mb-2 sm:mb-4'>Your Gateway to</h1>
          <h1 className='text-2xl sm:text-6xl text-gray-700 font-bold mb-2 sm:mb-4'>Innovative <span className='text-cyan-400 text-2xl sm:text-5xl font-bold'>Solutions!</span></h1>
          <div className='w-full sm:w-96 p-2 pb-6'>
            <button className="bg-blue-500 text-white px-6 sm:px-10 py-2 mt-2 uppercase rounded hover:bg-cyan-600">get started</button>
          </div>
        </div>
        <div className="flex-1 flex items-center justify-center pt-16 sm:mr-8  ">
          <img src={svg} alt="Your Alt Text" className="object-cover" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
