import React, { useState, useEffect, useRef } from 'react';

const CounterItem = ({ value, label, count }) => {
  return (
    <div className="lg:w-1/4 md:w-1/2 w-full text-center px-4 mb-8 lg:mb-0">
      <span className="text-5xl font-bold block" style={{ color: '#555186' }}>{count}</span>
      <p className="text-sm text-gray-600">{label}</p>
    </div>
  );
};

const Counters = () => {
  const [counts, setCounts] = useState({
    clients: 0,
    projects: 300,
    hoursOfSupport: 1000,
    hardWorkers: 0,
  });

  const countersRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const intervalId = setInterval(() => {
            setCounts((Counts) => ({
              clients: Math.min(274, Counts.clients + 1),
              projects: Math.min(421, Counts.projects + 1),
              hoursOfSupport: Math.min(1364, Counts.hoursOfSupport + 1),
              hardWorkers: Math.min(11, Counts.hardWorkers + 1),
            }));
          }, 10);

          return () => clearInterval(intervalId);
        }
      },
      { threshold: 0.5 } 
    );

    observer.observe(countersRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div className="container mx-auto px-4 mt-4 pt-20 pb-20" ref={countersRef}>
      <div className="flex flex-wrap -mx-4">
        <CounterItem value={274} label="Clients" count={counts.clients} />
        <CounterItem value={421} label="Projects" count={counts.projects} />
        <CounterItem value={1364} label="Hours Of Support" count={counts.hoursOfSupport} />
        <CounterItem value={11} label="Hard Workers" count={counts.hardWorkers} />
      </div>
    </div>
  );
};

export default Counters;
