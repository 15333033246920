import React from 'react';
import raheelImage from '../assets/raheel.jpeg';

import NoumanAli from '../assets/NoumanAli.jpeg';
import Zawar from '../assets/Zawar.jpeg';
import Komal from '../assets/komal.jpeg';
import arroj from '../assets/arooj.jpeg';
import founder from '../assets/founder.jpeg'

const TeamMember = ({ name, role, image, socialLinks }) => {
    
        const backgroundImageStyle = {
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        };
  return (
    <div className='group relative w-full aspect-square sm:gap-y-2   rounded overflow-hidden  ' style={backgroundImageStyle}>
  <div className='group-hover:translate-y-[0%] translate-y-[100%] mb-0 absolute bottom-0 p-2 border h-15 w-full duration-700 cursor-pointer ease-in-out bg-black opacity-75 flex flex-col justify-center items-center rounded'>
    <div className='text-center '>
      <h4 className='text-lg font-bold text-white'>{name}</h4>
    </div>
    <div className='text-center'>
      <p className='text-white'>{role}</p>
    </div>
  </div>
</div>

);
};

const Team = () => {
  const teamMembers = [
    {
      name: 'Umar Farooq',
      role: 'Founder',
      image: founder,
    
    },
    {
      name: 'Raheel Arshad',
      role: 'Chief Executive Officer',
      image: raheelImage,
     
      
    },
    {
      name: 'Arooj Naseem',
      role: 'HR Manger',
      image: arroj,
     
      
      
    },
    {
        name: 'Nouman Ali',
        role: 'Software Engineer',
        image: NoumanAli,
       
        
        
      },
      
    {
      name: 'Komal Kalsoom',
      role: 'Software Engineer',
      image: Komal,
     
      
      
    },
    {
      name: 'Zawar Hussain',
      role: 'Associate Software Engineer',
      image: Zawar,
     
      
      
    },
    
    // {
    //   name: 'Arooj Naseem',
    //   role: 'HR Manger',
    //   image: arroj,
     
      
      
    // },
    // Add more team members as needed
  ];

  return (
    <div className='bg-[#f5f8fd] flex items-center flex-col'>
      <div className='flex flex-col w-full items-center justify-center p-10  ' id='team' style={{ background: '#f5f8fd' }}>
        <div className='text-5xl font-bold' style={{ color: '#413e66' }}>
          <h1>Team</h1>
        </div>
        <div className='pt-2' style={{ color: '#535074' }}>
          <p>But that you may see whence all this error is born, the pleasure and pain of those who accuse</p>
        </div>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-3 w-[95%] md:w-[80%] self-center p-12 justify-around sm: gap-8 pb-10 items-center' style={{ background: '#f5f8fd' }}>
        {teamMembers.map((member, index) => (
          <TeamMember key={index} {...member} />
        ))}
      </div>
    </div>
  );
};

export default Team;
