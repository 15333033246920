import React from "react";
import { FaCircleCheck } from "react-icons/fa6";
import { useEffect } from "react";
import WOW from 'wowjs';

const Feathurs = ({ selectedItem }) => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  
  const items = [selectedItem];

  return (
    <>
      {items.map((item, index) => (
        <div key={index}>
          <div className="flex justify-center items-center">
            <h1 className="text-4xl sm:text-5xl font-semibold text-gray-700 p-4">
              High Level Features Of {item.main.title}
            </h1>
          </div>

          <div className="p-4 flex flex-col gap-y-10 pt-10">
            {item.features.map((feather, featherIndex) => (
              <div key={featherIndex} className={`grid grid-cols-1 md:grid-cols-2`}>
                <div className={` ${featherIndex % 2 !== 0 && "md:order-2 "} `}>
                  <img src={feather.image} alt="Feather" className="wow animate__animated animate__bounceInRight  duration-300  " />
                </div>

                <div>
                  <h1 className="text-4xl font-bold p-2 pl-4 border-black" style={{ color: "#413e66" }}>
                    {feather.title}
                  </h1>

                  <div className="p-4 wow animate__animated bounceInUp  duration-300 ">
                    <ul>
                      {feather.benefits.map((benefit, benefitIndex) => (
                        <li key={benefitIndex} className="flex items-center space-x-3 pt-3 pb-3 ">
                         <div className="'min-w-[30px]'"> <FaCircleCheck className="text-white font-bold" color="blue" size={24} /></div>
                          <span className="text-xl text-gray-700">{benefit}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default Feathurs;
