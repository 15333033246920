import React from "react";

import { FaLinkedinIn, FaInstagram, FaFacebookF, FaTwitter } from "react-icons/fa";
import EmailForm from "./EmailForm";

const Clientsection = () => {
  return (
    <div id="footer">
      <div
        className="w-full mx-auto flex flex-col lg:flex-row justify-between items-start p-6 lg:p-12 "
        style={{ background: "#f5f8fd" }}
      >
        {/* Small Div 1 */}
        <div className="w-full lg:w-80 h-64    lg:mb-0 ">
          <div className="mt-4  lg:">
            <h1 className="text-4xl pb-3" style={{ color: "#413e66" }}>
              BITLOGICS
            </h1>
            <p className="" style={{ color: "#535074" }}>
              At BitLogics, we're just a message away from turning your digital
              dreams into reality. Feel free to reach out with your inquiries,
              ideas, or collaboration proposals, and let's start a conversation
              about your next tech venture.
            </p>
          </div>
        </div>

        {/* Small Div 2 */}
        <div className="w-full lg:w-96 h-64   ">
          <div className="mt-6 lg: pl-2">
            <h2 className=" font-semibold pb-3 uppercase " style={{ color: "#413e66" }}>
              Contact us
            </h2>
            <div className="flex items-start pt-2">
              <h2 className="font-bold" style={{ color: "#413e66" }}>
                Address:
              </h2>
              <span className="ml-2  pl-1lg:ml-0">
                702-A Al Hafeez Shopping Mall Main Blvd Gulberg III Lahore
              </span>
            </div>
            <div className="flex items-center">
              <h2 className="font-bold" style={{ color: "#413e66" }}>
                Phone:
              </h2>
              <span className="ml-2 pl-1 lg:ml-0">+92 325 9756219</span>
            </div>
            <div className="flex items-center">
              <h2 className="font-bold" style={{ color: "#413e66" }}>
                Email:
              </h2>
              <span className="ml-2 pl-1 lg:ml-0">bit.logics.sol@gmail.com</span>
            </div>
            <div className="flex flex-row py-2 px-0 space-x-2">
              <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                <div
                  className="w-12 h-12 pr-2 rounded-full  "
                  style={{ background: "#0a98c0" }}
                >
                  <FaTwitter className="text-white pl-3 pt-3  hover:text-cyan-800 duration-300 ease-in-out" size={34} />
                </div>
              </a>
              <a href="https://facebook.com/" target="_blank" rel="noopener noreferrer">
                <div
                  className="w-12 h-12 rounded-full "
                  style={{ background: "#0a98c0" }}
                >
                  <FaFacebookF className="text-white pl-3 pt-3 hover:text-cyan-800 duration-300 ease-in-out" size={34} />
                </div>
              </a>
              <a href="https://instagram.com/" target="_blank" rel="noopener noreferrer">
                <div
                  className="w-12 h-12 rounded-full  "
                  style={{ background: "#0a98c0" }}
                >
                  <FaInstagram className="text-white pl-3 pt-3 hover:text-cyan-800 duration-300 ease-in-out" size={34} />
                </div>
              </a>
              <a href="https://linkedin.com/" target="_blank" rel="noopener noreferrer">
                <div
                  className="w-12 h-12 rounded-full   "
                  style={{ background: "#0a98c0" }}
                >
                  <FaLinkedinIn className="text-white pl-3 pt-3 hover:text-cyan-800 duration-300 ease-in-out" size={34} />
                </div>
              </a>
            </div>
          </div>
        </div>

        {/* Big Div */}
        <div
          className="w-full lg:w-1/2 p-2 "
        >
          <EmailForm/>
        </div>
      </div>
    </div>
  );
};

export default Clientsection;
