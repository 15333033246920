import React from "react";
import PropTypes from 'prop-types';
import { FaGears } from "react-icons/fa6";
import { CiDeliveryTruck } from "react-icons/ci";
import { FaRegFaceAngry } from "react-icons/fa6";
import { useEffect } from "react";
import WOW from 'wowjs';


const Problems = ({ selectedItem }) => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  const items = [selectedItem];
  console.log("problems:", items[0].problems); 
  const problemsData = [
    { icon: <FaGears size={52} />, text: items[0].problem1 },
    { icon: <CiDeliveryTruck size={52} />, text: items[0].problem2 },
    { icon: <FaRegFaceAngry size={52} />, text: items[0].problem3 },
    { icon: <FaGears size={52} />, text: items[0].problem4 },
    
  ];
  return (
    <div>
   
      <div className=" ">
      {items.map((item, index) => (
        <div className="grid grid-cols-1 sm:grid-cols-2 h-full w-full pt-8    ">
          <div
            className="w-full h-full  md:p-16 pl-3 pr-3  flex items-center justify-center   wow animate__animated animate__bounceInUp  duration-300        "
            style={{ background: "#f5f8fd" }}
          >
            <img
              src={item.problems.problem_Image}
              className=" w-full object-cover md:p-16  "
              alt="Banner"
            />
          </div>

          <div
            className="flex flex-col items-center justify-center w-full h-full p-8 sm:p-4  "
            style={{ background: "#f5f8fd" }}
          >
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-4xl font-semibold mb-6 mr-auto md:ml-8  text-gray-700">
             Problem
            </h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-24 ">
              {/* {problemsData.map((problem, index) => ( */}
                <div key={index} className="h-48 w-64  flex flex-col items-center justify-center bg-white shadow-lg ">
                 {/* {problem.icon} */}<FaGears size={52}/>
                  <p className="text-center text-gray-700 pt-2">
                    {item.problems.problem1}
                   
                    
                   
                  </p>

                </div>
                <div key={index} className="h-48 w-64  flex flex-col items-center justify-center bg-white shadow-lg ">
                 {/* {problem.icon} */}<CiDeliveryTruck size={52}/>
                  <p className="text-center text-gray-700 pt-2">
                    {item.problems.problem2}
                   
                    
                   
                  </p>

                </div>
                <div key={index} className="h-48 w-64  flex flex-col items-center justify-center bg-white shadow-lg ">
                 {/* {problem.icon} */}<FaRegFaceAngry size={52}/>
                  <p className="text-center text-gray-700 pt-2">
                    {item.problems.problem3}
                   
                    
                   
                  </p>

                </div>
                <div key={index} className="h-48 w-64  flex flex-col items-center justify-center bg-white shadow-lg ">
                 {/* {problem.icon} */}<FaGears size={52}/>
                  <p className="text-center text-gray-700 pt-2">
                    {item.problems.problem4}
                   
                    
                   
                  </p>

                </div>
              {/* ))} */}
            </div>
            </div>
          </div>
          <div>
          </div>
        </div>
        ))}
      </div>
    </div>
  );
};

export default Problems;
