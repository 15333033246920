import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from "react";
import WOW from 'wowjs';


const Prodetail = ({selectedItem }) => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  const items = [selectedItem];
  return (
    <div className=''>
      {items.map((item, index) => (
        <div key={index} className='grid grid-cols-1 sm:grid-cols-2 h-full  wow animate__animated animate__bounceInUp duration-300  '>
          <div className='flex flex-col justify-center items-center p-8 md:p-12'>
            <div className='w-full   '>
              <h1 className='text-2xl sm:text-5xl font-semibold text-gray-700'>
                {item.intro.title}
              </h1>
              <p className='text-gray-700 text-base sm:text-xl pt-5'>
                {item.intro.description}
              </p>
            </div>
          </div>

          <div className='flex w-full justify-center items-center    '>
            <div className='w-full h-3/4 sm:h-full pl-7 pr-7 object-cover  '>
              <img
                src={item.intro.introImage
}
                className='h-full w-full  rounded-lg '
                alt={item.alt}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};


export default Prodetail;
