import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'animate.css/animate.min.css';
import WOW from 'wowjs';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';


const root = ReactDOM.createRoot(document.getElementById('root'));
const wow = new WOW.WOW();
wow.init();
root.render(
  // <React.StrictMode>
  <BrowserRouter>
  <ScrollToTop>
  <App />
  </ScrollToTop>
  </BrowserRouter>
  // const wow = new WOW.WOW();

  // </React.StrictMode>
);

