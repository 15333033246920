import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '@ramonak/react-progress-bar';
import { useEffect } from "react";
import WOW from 'wowjs';

const Range = ({ selectedItem }) => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  const items = [selectedItem];

  return (
    <div>
      {items.map((item, index) => (
        <div key={index} className='grid grid-cols-1  sm:grid-cols-2   w-full   gap-y-16 md:gap-y-0   my-16  '>
          <div className='flex flex-col justify-self items-center  p-8 sm:p-12 sm: my-auto  '>
            <div className='w-full  wow animate__animated animate__bounceInRight  duration-300   '>
              <h1 className='text-4xl sm:text-6xl font-semibold pb-5 text-gray-700'>Did You Know?</h1>
              <div>
                {item.about.infos.map((info, infoIndex) => (
                  <React.Fragment key={infoIndex}>
                    <p className='pt-2 text-xl text-gray-700'>{info.desc}</p>
                    <ProgressBar completed={info.value} animateOnRender={true} className='py-2' />
                  </React.Fragment>
                ))}
              </div>

            </div>
          </div>

          <div className='flex w-[90%] mx-auto justify-center items-center md:p-8    h-[300px] md:h-full  my-auto wow animate__animated animate__bounceInUp  duration-300   '>
          
              <img src={item.about.doyouknowImg} className='  h-full object-cover p-3 my-auto mx-2' alt='Banner' />
           
          </div>
        </div>
      ))}
    </div>
  );
};

Range.propTypes = {
  selectedItem: PropTypes.object,
};

export default Range;
