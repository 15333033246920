import React, { useEffect } from 'react';
import whyus from '../assets/why-us.jpg';
import { IoDiamondOutline } from 'react-icons/io5';
import { FaRegObjectGroup } from 'react-icons/fa';
import { FaLanguage } from 'react-icons/fa6';
import WOW from 'wowjs';
import 'animate.css/animate.min.css';
import { LiaLanguageSolid } from "react-icons/lia";

const FeatureItem = ({ icon, color, title, description, animationDuration ='2000ms' }) => {
  return (
    <div className={`features wow animate__animated animate__bounceInUp animate-duration-${animationDuration} clearfix sm:flex sm:items-start `}>
      <div className='pt-3'>
        <div className="flex justify-start items-center">
          {icon}
          <h4 className='text-3xl font-semibold pl-3' style={{ color: '#413e66' }}>{title}</h4>
        </div>
        <p className='pl-12 pt-2 pr-0 ' style={{ color: '#555186' }}>
          {description}
        </p>
      </div>
    </div>
  );
};

const Chooseus = () => {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <>
      <div className='flex flex-col items-center p-4 md:p-10 '>
        <div className='text-2xl md:text-4xl font-bold pb-4 md:pb-6' style={{ color: '#413e66' }}><h1>Why choose us?</h1></div>
        <div className='text-center w-full md:w-1/2 pb-4 md:pb-8'><p style={{ color: "#535074" }}>At BitLogics, we understand that choosing the right technology partner for your projects is a critical decision.</p></div>
      </div>
      <div className='flex flex-col sm:flex-row h-full w-full'>
        <div className='w-full sm:w-1/2 p-3'>
          <img src={whyus} alt='Why Us' className='w-full h-80 object-cover' />
        </div>
        <div className='w-full sm:w-1/2 '>
          <div className="p-4 md:p-0">
            <p className=' pt-2 pb-4 md:pb-8' style={{ color: '#555186' }}>
              Our team comprises skilled developers, designers, and tech enthusiasts who are not just experts in their fields but also passionate about technology. We stay at the forefront of industry trends and technologies to bring you innovative solutions.
            </p>
            <p className='pb-4 md:pb-8' style={{ color: '#555186' }}>
              Choosing BitLogics means choosing a dedicated and innovative partner for your digital projects. Let us turn your vision into reality, empower your business, and take you to new heights in the digital world.
            </p>

            <FeatureItem
              icon={<IoDiamondOutline size={38} style={{ color: '#f058dc' }} />}
              title='Client-Centric Approach'
              description='Your success is our success. We prioritize your unique needs and tailor our services accordingly. We believe in open communication, collaboration, and putting you at the center of every project.'
            //   animationDuration='500ms'
            />

            <FeatureItem
              icon={<FaRegObjectGroup size={38} style={{ color: '#ffb774' }} />}
              title='Technology-Driven Solutions'
              description='We are not just about keeping up with technology; we are about leading the way. We harness the power of technology to drive digital transformation and help you stay ahead in an ever-evolving tech landscape.'
            //   animationDuration='500ms'
            />

            <FeatureItem
              icon={<LiaLanguageSolid size={38} style={{ color: '#589af1' }} />}
              title='Proven Success'
              description='Our track record of successful projects and satisfied clients speaks for itself. We have a history of delivering results and elevating businesses in various industries.'
            //   animationDuration='500ms'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Chooseus;
