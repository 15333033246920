import React from 'react';
import about from '../assets/about-img.jpg';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

const Aboutus = () => {
  return (
    <>
      <div className=' overflow-hidden flex flex-col sm:flex-row min-h-screen px-4 sm:px-20 py-2   ' id="aboutus">
        <div className='bg-gradient-to-r from-gray-300 to-transparent sm:flex-4 h-screen p-8  box-decoration-slice'>
          <img
            src={about}
            alt="About"
            className="object-cover h-full w-full transition-transform transform hover:scale-105 duration-300"
          />
        </div>
        <div className='flex-3 w-full sm:w-3/4 p-4 sm:px-2   '>
          <h1 className='text-3xl font-bold py-4' style={{color:'#413e66'}}>About Us</h1>
          <h2 className='text-2xl py-2' style={{ color: '#696592',fontStyle: 'italic' }}>Your Vision, Our Code</h2>
          <p className='py-1' style={{ color: '#535074' }}> We’re a custom software development firm that works with startups, SMBs & enterprises to craft disruptive digital products & strategies that solve business problems & drive measurable results. We nurture entrepreneurial spirit with corporate expertise & experience, best in class tools & frameworks, Agile methods & talent to create an ecosystem that enables both startups & established companies to innovate efficiently with us. We continue to provide strategic value in the days, weeks & years after the products launch. By offering insight & candid counsel at every stage of the engagement, we consider ourselves to be a partner and not just a vendor</p>
          <ul className='py-2 sm:flex sm:flex-col  '>
          <li className='flex items-center py-1 ' style={{ color: '#535074' }}><div className='min-w-[32px]'><IoMdCheckmarkCircleOutline size={22} color='#1bb1dc' className='mr-2 text-green-500' /></div>Our team consists of skilled developers, designers, and tech enthusiasts passionate about their work.</li>
            <li className='flex items-center py-1 ' style={{ color: '#535074' }}><div className='min-w-[32px]'><IoMdCheckmarkCircleOutline size={22} color='#1bb1dc' className='mr-2 text-green-500' /></div>We're not just about keeping up; we're about leading the way in technology and innovation.</li>
            {/* <li className='flex items-center py-1'><IoMdCheckmarkCircleOutline size={24} className='mr-2 text-green-500' />Your success is our success.</li> */}
            <li className='flex items-center py-1 ' style={{ color: '#535074' }}><div className='min-w-[32px]'><IoMdCheckmarkCircleOutline size={22} color='#1bb1dc' className='mr-2 text-green-500' /></div>We prioritize your unique needs and tailor our solutions accordingly.</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
