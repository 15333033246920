import React from 'react';

const Fotter = () => {
  return (
    <div className='h-20 w-full sm:w-screen flex justify-center items-center'>
      <div className='w-80 sm:w-96 text-center'>
        <p style={{ color: '#535074' }}>© Copyright <span className='font-semibold'>Bitlogics.</span> All Rights Reserved</p>
      </div>
    </div>
  );
}

export default Fotter;
