import React, { useEffect, useState } from "react";
import WOW from "wowjs";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../Utility/Firebase";
import { useNavigate } from "react-router-dom";
// import useLocation from 'react-router-dom'

import "animate.css/animate.min.css";

const Portfolio = () => {
  const db = getFirestore();
  const [allData, setAllData] = useState([]);
  const [animatedClass, setAnimatedClass] = useState("");
  // const location = useLocation();

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    getAllDataWithDownloadURL();
  }, []);

  const navigate = useNavigate();

  const navigateToDetails = (item) => {
    setAnimatedClass("animated-slide-in-from-top");
    navigate("/details", { state: { selectedItem: item } });
    console.log(item, " thi is item");
  };

  const getAllDataWithDownloadURL = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "projects"));
      const fetchedData = [];

      for (const doc of querySnapshot.docs) {
        const data = { id: doc.id, ...doc.data() };

        // Assuming there's a field named 'fileRef' containing the reference to the file in Firestore Storage
        if (data.fileRef) {
          // Use getDownloadURL to get the download URL for the file
          const downloadURL = await getDownloadURL(data.fileRef);
          data.downloadURL = downloadURL;
        }

        fetchedData.push(data);
      }

      // Update the state with the fetched data
      setAllData(fetchedData);
      console.log("All Data with Download URLs:", fetchedData);
    } catch (error) {
      console.error("Error getting data:", error);
    }
  };

  // Call the function to get all data with download URLs

  return (
    <>
      <div className="flex items-center justify-center p-10" id="portfolio">
        <div className="text-5xl font-bold " style={{ color: "#413e66" }}>
          <h1>Portfolio</h1>
        </div>
      </div>
      <div className="grid   grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10 w-[95%] mx-auto rounded flex-row wow animate__animated animate__bounceInUp duration-1500 ">
        {allData.map((item, index) => (
          <div
            key={index}
            className={`flex flex-col mb-0 md:mb-0 shadow-2xl  rounded-lg ${animatedClass}`}

          >
            <div className=" w-full "> 
              <div className="relative group aspect-w-16 overflow-hidden border-black">
                <img
                  src={item.main.coverImage}
                  alt={`Portfolio ${index + 1}`}
                  className="object-cover h-96 w-full transition-transform transform duration-300 group-hover:scale-110 rounded-lg"
                />
              </div>
            </div>

            <div className="w-full p-2  pb-1">
              <h1 className="text-2xl font-bold pt-2 pb-1">
                {item.main && item.main.title}
              </h1>
              <p className="pb-4 text-gray-700">
                {item.main && item.main.shortDescription}
              </p>
              {/* Add button and other UI elements as needed */}
              <button
                className="bg-blue-500 border border-blue-500 text-white px-8 py-3 rounded hover:bg-transparent hover:text-blue-500"
                onClick={() => navigateToDetails(item)}
              >
                View details
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default Portfolio;
