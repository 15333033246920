import React from 'react'
import Home from './Pages/Home'

import { Route,Routes } from 'react-router-dom'
import Details from './Pages/Details'

const App = () => {
  return (
<>
<Routes>
        <Route path="/" element={<Home />} />
        <Route path="/details" element={<Details />} />
      </Routes>
</>
  )
}

export default App