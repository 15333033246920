import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import WOW from 'wowjs';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import raheelImage from '../assets/raheel.jpeg';
import umerImage from '../assets/umar.jpeg';
import damy from '../assets/damy3.png'

const Testimonials = () => {
  // Initialize WOW.js on component mount
  React.useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  // Testimonials data
  const testimonialsData = [
    {
      id: 1,
      image: damy,
      name: 'IONUT-COSMIN',
      role: 'CTO',
      content: 'Hired Raheel multiple times on different project with Ionic as the final project. Was fast in responses, and I recommend him on his work that worked great on our solutions. Highly recommend!',
    },
    {
      id: 2,
      image: damy,
      name: 'David C.',
      role: 'Project Manager',
      content: 'Raheel has done a great job with a several applications for me and even repaired code bases written by other developers. I highly recommend him.',
    },
    // Add more testimonials if needed
  ];

  return (
    <div id="testimonials" className="wow fadeInUp  p-8 w-full  flex justify-center items-center ">
      <div className="container w-full  ">
        <header className="section-header text-center mb-8 pt-2">
          <h3 className="text-4xl font-bold " style={{ color: '#413e66' }}>Testimonials</h3>
        </header>

        <Swiper
          className=" "
          spaceBetween={0}
          navigation={true }
          pagination={false}
          loop={true}
          autoplay={{ delay: 3000 }}
          modules={[Navigation, Autoplay, Pagination]}
        >
          {testimonialsData.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <div className="text-center p-4 w-full ">
                <img src={testimonial.image} className="rounded-full mb-4 mx-auto w-20 h-20" alt="" />
                <h3 className="text-xl font-bold">{testimonial.name}</h3>
                <h4 className="text-gray-600">{testimonial.role}</h4>
                <p className="text-gray-700 w-4/5 mx-auto">{testimonial.content}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonials;
