import React from 'react';
import ServiceBox from './Servicebox';
import { FaUsersRays } from "react-icons/fa6";
import { FaMobile } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { SiBlockchaindotcom } from "react-icons/si";
import { TbWorld } from "react-icons/tb";
import { CiClock2 } from "react-icons/ci";


const Service = () => {
  const serviceData = [
    {
        bgColor: '#fceef3',
      iconColor: '#ff689b',
      title: 'User Experience',
      description: 'We design with the end-user in mind, ensuring intuitive and delightful interactions that drive satisfaction.',
      Icon: () => <FaUsersRays size={22} />
    },
    {
      bgColor: '#fff0da',
      iconColor: '#e98e06',
      title: 'Mobile Development',
      description: 'We design and develop mobile apps that captivate users, ensuring a seamless and engaging journey on every device.',
      Icon: () => <FaMobile size={22} className=''/>
    },
    {
      bgColor: '#e6fdfc',
      iconColor: '#3fcdc7',
      title: 'Web Development',
      description: 'We create dynamic websites that deliver engaging user experiences and drive your online success.',
      Icon: () => <CgWebsite size={22}/>
    },
    {
      bgColor: '#eafde7',
      iconColor: '#41cf2e',
      title: 'Blockchain Development',
      description: 'We specialize in developing decentralized applications and smart contracts that enhance data integrity.',
      Icon: () => <SiBlockchaindotcom size={22}/>
    },
    {
      bgColor: '#e1eeff',
      iconColor: '#2282ff',
      title: 'Ecommerce Development',
      description: 'We design e-commerce platforms that captivate customers, streamline transactions, and boost online sales',
      Icon: () => <TbWorld size={22} />
    },
    {
      bgColor: '#ecebff',
      iconColor: '#8660fe',
      title: 'Cloud Services',
      description: 'We provide cloud services that optimize your business operations, enhance scalability, and ensure data security',
                  
      Icon: () => <CiClock2 size={22} />
    },
    // Add more service items if needed
  ];

  return (
    <>
      <div className='h-full w-full mx-auto pt-10 ' id='services' style={{ background: '#f5f8fd' }}>
        <div className='flex flex-col items-center p-4'>
          <div className='text-4xl font-bold pb-4' style={{ color: '#413e66' }}><h1>Services</h1></div>
          <div><p style={{color:"#535074"}}>We offer a wide range of services, including:</p></div>

          <div className='flex flex-wrap w-[80%] justify-center pt-4 gap-x-4 gap-y-6 sm:gap-x-8 sm:gap-y-12ee md:gap-x-10 md:gap-y-10 lg:gap-x-12 lg:gap-y-12 pb-20 '>
            {serviceData.map((data, index) => (
              <ServiceBox key={index} data={data} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
